<template>
  <div class="in-conatiner">
    <center>
      <div class="b-intro">
        <div class="b-intro2">
          <div class="intro">
            <div class="core"></div>
            <div class="intro-c">
              <i data-feather="home"></i>
              <h1 class="intr"></h1>
            </div>
          </div>
          <div class="container">
            <router-view />
          </div>
          <div id="footer" class="container-fluid">
            <center>
              <ul>
                <a href="#">
                  <li>
                    <PhInstagramLogo :size="30" color="white" weight="bold" />
                  </li>
                </a>
                <a href="#">
                  <li><PhGithubLogo :size="30" color="white" weight="bold" /></li>
                </a>
                <a href="#">
                  <li>
                    <PhTwitterLogo :size="30" color="white" weight="bold" />
                  </li>
                </a>
                <a href="#">
                  <li>
                    <PhLinkedinLogo :size="30" color="white" weight="bold" />
                  </li>
                </a>
                <a href="#">
                  <li>
                    <PhFacebookLogo :size="30" color="white" weight="bold" />
                  </li>
                </a>
              </ul>
              <h5>by <a href="https://iws.ibhate.com">IwS </a><PhHeart :size="15" color="white" weight="bold" /></h5>
            </center>
          </div>
        </div>
        <SquarAnimate />
      </div>
    </center>
  </div>
</template>

<script>
import SquarAnimate from "@/components/SquarAnimate.vue";
import { PhFacebookLogo, PhGithubLogo, PhInstagramLogo, PhLinkedinLogo, PhTwitterLogo, PhHeart } from "@phosphor-icons/vue";

export default {
  components: {
    SquarAnimate,
    PhFacebookLogo,
    PhGithubLogo,
    PhInstagramLogo,
    PhLinkedinLogo,
    PhTwitterLogo,
    PhHeart,
  },
};

var $ = require("jquery");

$(document).on(
  {
    mouseenter: function () {
      $(".cursor-dot").addClass("cursor-dot-act");
    },
    mouseleave: function () {
      $(".cursor-dot").removeClass("cursor-dot-act");
    },
  },
  "a"
);
(function ($) {
  var timer;

  Number.prototype.range = function (min, max) {
    return this > min && this < max;
  };

  var rd = function randomAlphaNum() {
    var rand = Math.floor(Math.random() * (122 - 33 + 1) + 33);
    if (rand.range(38, 48)) rand = rand + 10;
    else if (rand.range(57, 63)) rand = rand + 10;
    else if (rand.range(90, 97)) rand = rand + 10;

    return String.fromCharCode(rand);
  };

  $.fn.intrStart = function (begin) {
    this.each(function () {
      var $ele = $(this),
        str = "I am Ayoub",
        i = 0,
        replace = /[^\s]/g;

      $ele.text("");

      timer = setInterval(function () {
        $ele.text(
          str.substring(0, i) +
            str
              .substring(i, str.length)

              .replace(replace, rd)
        );

        if (begin) {
          i++;

          if (i > str.length) clearInterval(timer);
        }
      }, 210); // Timing
    });
    return this;
  };

  $.fn.intrStop = function () {
    clearInterval(timer);
    return this;
  };

  $.fn.intrCenter = function () {
    this.css("position", "absolute");
    this.css("top", Math.max(0, ($(window).height() - $(this).outerHeight()) / 2.3 + $(window).scrollTop()) + "px");
    this.css("left", Math.max(0, ($(window).width() - $(this).outerWidth()) / 2 + $(window).scrollLeft()) + "px");
    return this;
  };
})($);
import img from "@/assets/img/ayoub.jpg";

setTimeout(function () {
  $(".core").html("<img src=" + img + " />");
}, 6600);
setTimeout(function () {
  $(".intr").fadeIn("slow");
  $(".intr").intrStart(true);
}, 4600);

setTimeout(function () {
  $(".about").addClass("wll-act");
}, 6800);
setTimeout(function () {
  $(".scots").addClass("wll-act");
}, 7000);
</script>
