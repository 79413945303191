<template>
  <div class="techPage" data-aos="zoom-in-up">
    <router-link to="/" class="back">
      <PhArrowLeft :size="20" color="white" weight="bold" />
    </router-link>
    <h3>What I Master ?</h3>
    <p>Basics of Web Dev.</p>
    <div class="lang-gr row">
      <div class="lang col-md">
        <div class="code">
          <vue-writer :array="['div.lang']" :start="1000" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
          <vue-writer :array="['<style>']" :start="3100" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
          <vue-writer :array="['.lang {']" :start="4800" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
          <vue-writer :array="['display: inline-table;']" :start="6000" :iterations="1" :intervals="100" :caret="underscore"></vue-writer>
          <vue-writer :array="['}']" :start="10400" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
          <vue-writer :array="['</style>']" :start="11000" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
        </div>
        <img src="https://iws.ibhate.com/img/tech/html.svg" style="display: inline-block" alt="html" />
        &nbsp;
        <img src="https://iws.ibhate.com/img/tech/css.svg" style="display: inline-block" alt="css" />
        <div>Html , Css</div>
      </div>
      <br />
      <div class="lang col-md" v-for="t in tb" :key="t.k">
        <div class="code">
          <vue-writer :array="t.arr1" :start="1000" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
          <vue-writer :array="t.arr2" :start="3100" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
          <vue-writer :array="t.arr3" :start="4800" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
          <vue-writer :array="t.arr4" :start="6000" :iterations="1" :intervals="100" :caret="underscore"></vue-writer>
          <vue-writer :array="t.arr5" :start="10400" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
          <vue-writer :array="t.arr6" :start="11000" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
        </div>
        <img :src="t.img" :alt="t.name" />
        <div v-text="t.name"></div>
      </div>
    </div>
    <p>Those are the frameworks I am good at.</p>
    <div class="lang-gr">
      <div class="lang" v-for="t in ts" :key="t.k">
        <div class="code">
          <vue-writer :array="t.arr1" :start="3000" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
          <vue-writer :array="t.arr2" :start="5100" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
          <vue-writer :array="t.arr3" :start="6800" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
          <vue-writer :array="t.arr4" :start="8000" :iterations="1" :intervals="100" :caret="underscore"></vue-writer>
          <vue-writer :array="t.arr5" :start="12400" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
          <vue-writer :array="t.arr6" :start="13000" :intervals="100" :iterations="1" :caret="underscore"></vue-writer>
        </div>
        <img :src="t.img" :alt="t.name" />
        <div v-text="t.name"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhArrowLeft } from "@phosphor-icons/vue";
const bs = "/";
export default {
  name: "TechView",
  components: {
    PhArrowLeft,
  },
  data: function () {
    return {
      bs: bs,
      tb: [
        {
          k: 1,
          img: "https://iws.ibhate.com/img/tech/js.svg",
          name: "Java script",
          arr1: [""],
          arr2: [""],
          arr3: ["const l = async () => {"],
          arr4: ["await type.start();"],
          arr5: ["}"],
          arr6: [""],
        },
        {
          k: 2,
          img: "https://iws.ibhate.com/img/tech/php.svg",
          name: "PHP",
          arr1: [""],
          arr2: ["function lang(nex) {"],
          arr3: ["$langs = [ 'html' , 'js' , 'php' ]"],
          arr4: ["return langs[nex] ;"],
          arr5: ["}"],
          arr6: [""],
        },
      ],
      ts: [
        {
          k: 1,
          img: "https://iws.ibhate.com/img/tech/laravel.svg",
          name: "Laravel",
          arr1: [""],
          arr2: ["public function reminder() {"],
          arr3: ["session()->put('later', 1) ;"],
          arr4: [" return response()->json('success' , 200) ;"],
          arr5: ["}"],
          arr6: [""],
        },
        {
          k: 2,
          img: "https://iws.ibhate.com/img/tech/vue.svg",
          name: "Vue.js",
          arr1: ["import { createApp } from 'vue';"],
          arr2: [""],
          arr3: ["const app = createApp(App);"],
          arr4: [""],
          arr5: ["app.use(router);"],
          arr6: ["app.mount('#app');"],
        },
        {
          k: 3,
          img: "https://iws.ibhate.com/img/tech/node.svg",
          name: "Node.js",
          arr1: ["const axios = require('axios');"],
          arr2: ["const StScrap = (url , lss = [] ) => {"],
          arr3: [""],
          arr4: ["axios.get(url).then((response) => { ... } "],
          arr5: ["}"],
          arr6: ["module.exports = StScrap ;"],
        },
        {
          k: 4,
          img: "https://iws.ibhate.com/img/tech/wordpress.svg",
          name: "WordPress",
          arr1: ["$gw = get_theme_mod('custom-logo');"],
          arr2: [""],
          arr3: ["$w = wp_get_attachement_img_src($gw);"],
          arr4: [""],
          arr5: ["<img src='<?php echo $w[0] ?>' />"],
          arr6: [""],
        },
        {
          k: 5,
          img: "https://iws.ibhate.com/img/tech/jquery.svg",
          name: "Jquery",
          arr1: ["$('.lang img').hover( function() {'"],
          arr2: [""],
          arr3: ["$(this).css({'filter':'none'});"],
          arr4: [""],
          arr5: ["});"],
          arr6: [""],
        },
        {
          k: 6,
          img: "https://iws.ibhate.com/img/tech/bootstrap.svg",
          name: "Bootstrap",
          arr1: ["<div class='d-flex justify-content-around' > "],
          arr2: [""],
          arr3: ["div.alert.alert-success"],
          arr4: [""],
          arr5: ["div.alert.alert-danger"],
          arr6: ["</div>"],
        },
      ],
    };
  },
};
</script>

<style scoped>
.lang-gr {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  position: relative;
  z-index: 3;
  margin-bottom: 100px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.lang {
  display: inline-table;
  width: 30%;
  padding: 80px 0;
  min-height: 300px;
  position: relative;
}
.lang .code {
  position: absolute;
  z-index: 0;
  opacity: 0.3;
  text-align: left;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.lang img {
  height: 80px;
  margin: 10px 0;
  display: block;
  filter: grayscale(100%) brightness(0.2) invert(1);
}
.lang:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.lang:hover img {
  filter: none;
}

/*skils */
#skils h4 {
  text-align: center;
  font-size: 30px;
}

.tab {
  display: inline-table;
  width: 30%;
  padding: 25px 30px;
  margin-top: 100px;
}
.tab img {
  width: 125px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 20px;
  filter: grayscale(100%);
}
.tab h4 {
  margin-bottom: 20px;
}
.tab h5 {
  font-size: 20px;
  color: #488bb3;
}

@media only screen and (max-width: 600px) {
  .lang-gr {
    width: 100% !important;
    margin: 20px 0 !important;
  }
  .lang {
    display: inline-block;
    padding: 30px 0;
    min-height: 200px;
    width: 100%;
  }
}
@media only screen and (max-width: 450px) {
  .lang-gr {
    width: 95%;
  }
  .lang {
    width: 100%;
  }
}
</style>
