<template>
  <div class="contactPage" data-aos="zoom-in-up">
    <h3>Let's Talk.</h3>
    <p>Anything in your mind now just send it to me , I am your new friend</p>
    <router-link to="/" class="back">
      <PhArrowLeft :size="20" color="white" weight="bold" />
    </router-link>
    <div id="contact">
      <center>
        <form class="frm" id="frm" @submit.prevent="sub" autocomplete="off">
          <input type="text" placeholder="Name" name="name" v-model="name" class="name" id="name" />
          <input type="email" placeholder="Email" name="email" v-model="email" class="email" id="email" /><br />
          <input type="tel" placeholder="Phone Number " v-model="phone" name="phone" class="phone" id="phone" />
          <input type="text" placeholder="Subject" v-model="subject" name="subject" class="budget" id="budget" /><br />
          <textarea class="message" name="msg" id="message" placeholder="details" v-model="msg"></textarea><br />
          <input type="submit" value="Send" class="sub" id="sub" />
        </form>
      </center>
    </div>
  </div>
</template>

<script>
import { PhArrowLeft } from "@phosphor-icons/vue";
let $ = require("jquery");
export default {
  name: "ContactView",
  components: {
    PhArrowLeft,
  },
  data: function () {
    return {
      name: "",
      email: "",
      subject: "",
      phone: "",
      msg: "",
    };
  },
  methods: {
    sub: function () {
      var name = document.getElementById("name").value;
      var email = document.getElementById("email").value;
      var phone = document.getElementById("phone").value;
      var budget = document.getElementById("budget").value;
      var message = document.getElementById("message").value;
      var check2 = new RegExp(/^[A-Za-z0-9._-]+@[A-Za-z]+\.[a-z]/);

      if (email == "") {
        $("#email").css("border-bottom", "1px solid red");
      }
      if (message == "") {
        $("#message").css("border-bottom", "1px solid red");
      } else if (check2.test(email)) {
        $.ajax({
          method: "post",
          url: "https://www.ibhate.com/api/send-mail",
          data: { name: name, email: email + " " + phone, why: "subject " + budget, content: message },
          success: function () {
            $("#name").val("");
            $("#email").val("");
            $("#phone").val("");
            $("#budget").val("");
            $("#message").val("");
            $("input").css("border-bottom", "1px solid white");
            alert("message sent :)");
          },
        });
      }
    },
  },
  watch: {
    name: function (v) {
      var check1 = new RegExp(/^[A-Za-z]/);
      if (check1.test(v)) {
        $("#name").css({ "border-bottom": "1px solid green" });
      } else {
        $("#name").css({ "border-bottom": "1px solid yellow" });
      }
    },
    email: function (v) {
      var check2 = new RegExp(/^[A-Za-z0-9._-]+@[A-Za-z]+\.[a-z]/);

      if (check2.test(v)) {
        $("#email").css({ "border-bottom": "1px solid green" });
      } else {
        $("#email").css({ "border-bottom": "1px solid yellow" });
      }
    },
    phone: function (v) {
      var check3 = new RegExp(/^[+0-9]/);

      if (check3.test(v)) {
        $("#phone").css({ "border-bottom": "1px solid green" });
      } else {
        $("#phone").css({ "border-bottom": "1px solid yellow" });
      }
    },
  },
};
</script>

<style scoped>
#contact {
  position: relative;
  z-index: 2;
  width: 100%;
}
.frm {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px 0;
  border-radius: 25px;
  margin-top: 50px;
  width: 1150px;
}
#contact h3 {
  color: white;
  font-size: 25px;
  margin: 30px 0;
}
.name,
.email,
.phone,
.budget {
  width: 450px;
  height: 50px;
  padding: 1px 10px;
  border: 0;
  border-bottom: 1px solid white;
  background-color: transparent;
  margin: 30px 45px;
  color: white;
  transition-duration: 1.5s;
  font-size: 16px;
}
input:focus {
  outline: none;
  border-bottom: 1px solid #062bff;
}
input::placeholder,
textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
}

.message {
  background-color: transparent;
  width: 1000px;
  resize: none;
  border: 0;
  border-bottom: 1px solid white;
  color: white;
  padding: 1px 11px;
  height: 100px;
  font-size: 16px;
}
.message:focus {
  outline: none;
  border-bottom: 1px solid #062bff;
}

.sub {
  background-color: transparent;
  border: 1px solid white;
  padding: 10px 40px;
  border-radius: 20px;
  color: white;
  transition-duration: 1s;
  margin-top: 20px;
}
.sub:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/*media screen*/
@media only screen and (max-width: 1200px) {
  .name,
  .email,
  .phone,
  .budget {
    width: 95%;
    height: 45px;
    margin: 25px 0;
  }
  .message {
    width: 95%;
  }
}
@media only screen and (max-width: 900px) {
  .frm {
    width: 100%;
    padding-top: 5px;
  }
}

@media only screen and (max-width: 450px) {
  .message {
    margin-top: 15px;
  }
  .message_not {
    left: 10px;
    font-size: 14px;
  }
}
</style>
