import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import AOS from "aos";
import VueWriter from "vue-writer";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(AOS.init({ duration: 50, offset: 100 }));
app.use(VueWriter);
app.mount("#app");
