import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TechView from "../views/TechView.vue";
import TestView from "../views/TestView.vue";
import ContactView from "../views/ContactView.vue";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/js/app.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/technologies",
    name: "technologies",
    component: TechView,
  },
  {
    path: "/testemonials",
    name: "testimonials",
    component: TestView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
