<template>
  <div class="techPage" data-aos="zoom-in-up">
    <router-link to="/" class="back">
      <PhArrowLeft :size="20" color="white" weight="bold" />
    </router-link>
    <h3>Testemonials</h3>
    <p>A few Testemonials from people I dealed with.</p>
    <div class="wrap">
      <h4 v-for="q in qs" :key="q.id" v-html="q.q"></h4>
    </div>
  </div>
</template>

<script>
import { PhArrowLeft } from "@phosphor-icons/vue";
var tr = "&nbsp;&nbsp;<span style=' font-size: 14px !important;opacity: 0.7 !important;'>translated from arbic</span>";
export default {
  name: "TestView",
  data: function () {
    return {
      qs: [
        {
          id: 1,
          q: "“He is very helpful and professional.”   <br><br>— Herbert Theodore",
        },

        {
          id: 2,
          q: "“Professional , very cooperative, and He understands what you want . We have more future projects , God willingThank you brother , from the heart” " + tr + "  <br><br>— Faheem Almas",
        },

        {
          id: 3,
          q: "“A creative person, one of the few talented people, he has a great future,  a professional web developer who can make anything you can imagine with programming.” " + tr + "  <br><br>— Mishaal Mufleh Al-Shammari",
        },

        {
          id: 4,
          q: "“Of course, He is kind and genius, and I was suffering from problems in my site, and he solved them all, and I wish him well and success” " + tr + "  <br><br>— Ali",
        },

        {
          id: 5,
          q: "“I highly recommend dealing with him , Ayoub is a respected young man, and his work is perfect. Do not hesitate to deal with him.. I wish you all the best, brother Ayoub, and more success” " + tr + "  <br><br>— Mubarak",
        },
        {
          id: 6,
          q: "“There is no other developer I would ever want to work with other than Ayoub. Highly recommend!!.”   <br><br>— Travis",
        },

        {
          id: 7,
          q: "“A smart programmer who finds quick and accurate solutions, speed of work, and a respectable person who accepts all opinions” " + tr + "  <br><br>— Khaled Ibn Al-Yaman",
        },

        {
          id: 8,
          q: "“He took my vague design ideas, raw text,  and turned them into a beautiful site.”   <br><br>— Brendon Terisa",
        },

        {
          id: 9,
          q: "“Creative & fast” " + tr + "  <br><br>— Hashem Gilani",
        },
        {
          id: 10,
          q: "“He does what it takes, I wish him all the best” " + tr + "  <br><br>— Mohamed Ezzat",
        },
      ],
    };
  },
  components: {
    PhArrowLeft,
  },
};
</script>

<style scoped>
.wrap {
  max-width: 800px;
}

h4 {
  position: relative;
  font-weight: 300;
  text-align: left;
  margin: 100px 0;
}
</style>
