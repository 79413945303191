<template>
  <!--animate-->
  <ul class="box">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
  <!--end-->
</template>

<script>
export default {
  name: "SquareAnimate",
};
</script>

<style scoped>
.box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: transparent;
  z-index: 1;
}
.box li {
  display: block;
  list-style: none;
  width: 25px;
  height: 25px;
  background-color: rgba(255, 255, 255, 0.3);
  animation: square-move 2.5s;
  bottom: -100px !important;
  position: absolute;
  z-index: 1;
  border-radius: 100%;
}
.box li:nth-child(1) {
  left: 86%;
  width: 15px;
  height: 15px;
  animation-delay: 0s;
}
.box li:nth-child(2) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 1.6s;
}
.box li:nth-child(3) {
  left: 45%;
  width: 20px;
  height: 20px;
  animation-delay: 1s;
}
.box li:nth-child(4) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 0.5s;
}
.box li:nth-child(5) {
  left: 5%;
  width: 20px;
  height: 20px;
  animation-delay: 2.2s;
}
.box li:nth-child(6) {
  left: 86%;
  width: 10px;
  height: 10px;
  animation-delay: 2s;
}
.box li:nth-child(7) {
  left: 65%;
  width: 10px;
  height: 10px;
  animation-delay: 3.2s;
}
.box li:nth-child(8) {
  left: 45%;
  width: 20px;
  height: 20px;
  animation-delay: 3s;
}
.box li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2.6s;
}
.box li:nth-child(10) {
  left: 5%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}
.box li:nth-child(11) {
  left: 86%;
  width: 15px;
  height: 15px;
  animation-delay: 3s;
}
.box li:nth-child(12) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 3.2s;
}
.box li:nth-child(13) {
  left: 45%;
  width: 10px;
  height: 10px;
  animation-delay: 4s;
}
.box li:nth-child(14) {
  left: 20%;
  width: 20px;
  height: 20px;
  animation-delay: 3.5s;
}
.box li:nth-child(15) {
  left: 5%;
  width: 30px;
  height: 30px;
  animation-delay: 4.2s;
}
@keyframes square-move {
  0% {
    transform: translateY(0px);
  }
  90% {
    transform: translateY(-130vh) translateX(auto);
  }
  98% {
    opacity: 1;
  }
  100% {
    left: 50%;
    transform: translateY(-106vh) rotate(360deg) translateX(-50%);
    opacity: 0.4;
  }
}
</style>
