<template>
  <div class="start row" data-aos="zoom-in-up">
    <div class="about col-md">
      <div>
        <vue-writer :array="['Hi ,']" :start="1000" :typeSpeed="140" :iterations="1" :caret="underscore"></vue-writer><br />
        <vue-writer :array="['I am very happy to have you come and see my e-profile']" :typeSpeed="80" :start="1800" :iterations="1" :caret="underscore"></vue-writer><br />
        <vue-writer :array="['I am Ayoub From Morocco']" :start="7000" :typeSpeed="120" :iterations="1" :caret="underscore"></vue-writer><br />
        <vue-writer class="role" :array="['a Programmer', 'a Web Developper', 'a Software Engineer', 'whatever you want to call it...!']" :start="10000" :typeSpeed="100" :caret="underscore"></vue-writer>
      </div>
    </div>
    <div class="scots col-md">
      <div class="d-flex justify-content-between lec">
        <router-link to="/technologies">
          <div class="test sc">
            <h2>What I Master ?</h2>
          </div>
        </router-link>
        <router-link to="/testemonials">
          <div class="test sc">
            <h2>What they say ?</h2>
          </div>
        </router-link>
      </div>
      <router-link to="/contact">
        <div class="cont sc">
          <h2>Contact</h2>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomevIew",
  computed: {},
};
</script>

<style scoped>
.role {
  font-size: 35px;
  animation: an 7s infinite;
}

@keyframes an {
  95% {
    color: royalblue;
  }
  100% {
    color: white;
  }
}
</style>
